<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Flash Card
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- En Front -->
          <validation-provider
            #default="validationContext"
            name="English Front"
            rules="required"
          >
            <b-form-group
              label="English Front"
              label-for="front-en"
            >
              <b-form-input
                id="front-en"
                v-model="flashCardData.front_en"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Ar Front -->
          <b-form-group
              label="Arabic Front"
              label-for="front-ar"
          >
            <b-form-input
                id="front-ar"
                v-model="flashCardData.front_ar"
                autofocus
                trim
            />
          </b-form-group>

          <!-- English Back -->
          <validation-provider
              #default="validationContext"
              name="English Back"
              rules="required"
          >
            <b-form-group
                label="English Back"
                label-for="back-en"
            >
              <b-form-input
                  id="back-en"
                  v-model="flashCardData.back_en"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Arabic Back -->
          <b-form-group
              label="Arabic Back"
              label-for="back-ar"
          >
            <b-form-input
                id="back-ar"
                v-model="flashCardData.back_ar"
                autofocus
                trim
            />
          </b-form-group>



          <!-- Course -->
          <validation-provider
            #default="validationContext"
            name="Choose Course"
            rules="required"
          >
            <b-form-group
              label="Choose Course"
              label-for="course"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="flashCardData.course_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="courseOptions"
                @input="val => updateCourseID(val)"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Category -->
          <validation-provider
              #default="validationContext"
              name="Choose Category"
              rules="required"
          >
            <b-form-group
                label="Choose Category"
                label-for="category"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="flashCardData.category_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoriesOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-role"
                  @input="(val) => categoryChanged(val)"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
              v-if="subCategoryOptions.length > 0"
              label="Select Sub Category"
              label-for="mc-is-active"
          >
            <validation-provider
                #default="{ errors }"
                name="Sub Category"
                rules="required"
            >
              <v-select
                  v-model="flashCardData.sub_category_id"
                  :options="subCategoryOptions"
                  :reduce="val => val.value"
                  :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
              label="Is Free Content"
              label-for="landmark"
          >
            <b-form-radio
                v-model="flashCardData.is_free_content"
                class="custom-control-primary"
                :name="'is_free_content_lectures'"
                value="1"
            >
              Yes
            </b-form-radio>
            <b-form-radio
                v-model="flashCardData.is_free_content"
                class="custom-control-primary"
                :name="'is_free_content_lectures'"
                value="0"
            >
              No
            </b-form-radio>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormTextarea,BFormRadio, BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormTextarea,
    BFormRadio,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  created() {

    this.$http.get('/all-courses')
        .then(response => {
          let that = this
          this.categoriesOptions.splice(0)
          this.lists.splice(0)
          this.lists = response.data.data.map(function(item){
            that.courseOptions.push({
              label : item.title_en,
              value: item.id+""
            })
          })
        })
  },
  methods: {
    categoryChanged(val){
      this.$http.get('/sub-categories?'+'course_id='+this.flashCardData.course_id+'&category_id='+val).then(res => {
        let that = this
        this.lists.splice(0)
        this.lists = res.data.data.map(function(item){
          that.subCategoryOptions.push({
            label : item.label,
            value: item.value+""
          })
        })
        this.lists.splice(0)
      }).catch(e => {
        reject()
      })
    },
    updateCourseID(course_id){
      this.$http.get('/categories?'+'course_id='+course_id)
          .then(response => {
            let that = this
            this.categoriesOptions.splice(0)
            this.lists.splice(0)
            this.lists = response.data.data.map(function(item){
              that.categoriesOptions.push({
                label : item.label,
                value: item.value+""
              })
            })
          })
    },
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      subCategoryOptions: [],
      required,
      courseOptions: [],
      lists: [],
      categoriesOptions: [],
    }
  },
  setup(props, { emit }) {
    const blankFlashCardData = {
      back_en: '',
      back_ar: null,
      front_en: '',
      front_ar: null,
      course_id: '',
      category_id: '',
      sub_category_id: '',
      is_free_content: null,
    }

    const flashCardData = ref(JSON.parse(JSON.stringify(blankFlashCardData)))
    const resetFlashCardData = () => {
      flashCardData.value = JSON.parse(JSON.stringify(blankFlashCardData))
    }

    const onSubmit = () => {
      let data = {
        back_en: flashCardData.value.back_en,
        back_ar: flashCardData.value.back_ar,
        front_en: flashCardData.value.front_en,
        front_ar: flashCardData.value.front_ar,
        course_id: flashCardData.value.course_id,
        category_id: flashCardData.value.category_id,
        sub_category_id: flashCardData.value.sub_category_id,
        is_free_content: flashCardData.value.is_free_content,
      }
      store.dispatch('flash-cards/addFlashCard', data)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFlashCardData)

    return {
      flashCardData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
