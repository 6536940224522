<template>

  <div>

    <flash-card-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :course-options="courseOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <flash-cards-list-filters
      :course-filter.sync="courseFilter"
      :category-filter.sync="categoryFilter"
      :subCategory-filter.sync="subCategoryFilter"
      :course-options="courseOptions"
      :category-options="categoryOptions"
      :sub-category-options="subCategoryOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="resetCourse"
              >
                <span class="text-nowrap">Add Flash Card</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refFlashCardsListTable"
        class="position-relative"
        :items="fetchFlashCards"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: verified -->
        <template #cell(is_free_content)="data">
          <free-content
              :row=data.item
              :module_be_base_url="'flash-cards'"
          >
          </free-content>
        </template>

        <template #cell(front_en)="data">
          <b-link
              class="font-weight-bold d-block text-nowrap"
              :to="{name : 'dashboard-edit-flash-card', params: {id: data.item.id}}"
          >
            {{ data.item.front_en }}
          </b-link>
        </template>

        <template #cell(id)="data">
          <b-link
              class="font-weight-bold d-block text-nowrap"
              :to="{name : 'dashboard-edit-flash-card', params: {id: data.item.id}}"
          >
            {{ data.item.id }}
          </b-link>
        </template>

        <!-- Column: Status -->
        <template #cell(is_active)="data">
          <status
              :row=data.item
              :module_be_base_url="'flash-cards'"
          >
          </status>
        </template>


        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
                :id="`edit-row-${data.item.id}-send-icon`"
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
                @click="$router.push({name : 'dashboard-edit-flash-card', params: {id: data.item.id}})"
            />
            <b-tooltip
                title="Edit"
                class="cursor-pointer"
                :target="`edit-row-${data.item.id}-send-icon`"
            />

            <feather-icon
                :id="`trash-row-${data.item.id}-send-icon`"
                icon="TrashIcon"
                style="margin-left: 1rem!important;"
                @click="deleteItem(data.item.id)"
                class="cursor-pointer"
                size="16"
            />
            <b-tooltip
                title="Delete"
                class="cursor-pointer"
                :target="`trash-row-${data.item.id}-send-icon`"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalFlashCards"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import FlashCardsListFilters from './FlashCardsListFilters.vue'
import useFlashCardsList from './useFlashCardsList'
import flashcardStoreModule from '../flashcardStoreModule'
import FlashCardListAddNew from './FlashCardListAddNew.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    FlashCardsListFilters,
    FlashCardListAddNew,

    BCard,
    BTooltip,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      lists: [],
      courseOptions: [],
      categoryOptions: [],
      subCategoryOptions: [],
    }
  },

  created() {
    this.$http.get('/all-courses').then(response => {
      let that = this
      this.lists = response.data.data.map(function(item){
        that.courseOptions.push({
          label : item.title_en,
          value: item.id+""
        })
      })
    })
  },

  methods: {
    resetCourse(){
      this.categoriesOptions = []
      this.isAddNewUserSidebarActive = true
    },
    deleteItem(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.onDelete(id)
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    editLecture(id){
      this.$http.get('/lectures/'+id).then(response => {
        this.lectureData.title_en = response.data.title_en
      })
      this.isAddNewUserSidebarActive = true
    }
  },

  setup() {
    const FLASH_CARD_STORE_MODULE_NAME = 'flash-cards'

    const toast = useToast()

    // Register module
    if (!store.hasModule(FLASH_CARD_STORE_MODULE_NAME)) store.registerModule(FLASH_CARD_STORE_MODULE_NAME, flashcardStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FLASH_CARD_STORE_MODULE_NAME)) store.unregisterModule(FLASH_CARD_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const onDelete = (flashCardId) => {

      store.dispatch('flash-cards/deleteFlashCard', flashCardId)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Flash Card Deleted Successfully',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
            refetchData()
          })
    }

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const courseOptions = []
    const categoryOptions = []
    const subCategoryOptions = []

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchFlashCards,
      tableColumns,
      perPage,
      currentPage,
      totalFlashCards,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFlashCardsListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      courseFilter,
      categoryFilter,
      subCategoryFilter,
    } = useFlashCardsList()

    return {
      onDelete,

      // Sidebar
      isAddNewUserSidebarActive,

      fetchFlashCards,
      tableColumns,
      perPage,
      currentPage,
      totalFlashCards,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFlashCardsListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,
      courseOptions,
      categoryOptions,
      subCategoryOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      courseFilter,
      categoryFilter,
      subCategoryFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
